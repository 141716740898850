import { createSignal, onCleanup, onMount, Show } from "solid-js";
import { CloseModalButton } from "~/components/modal";
import { PreReviewPopupDetails } from "~/server/types/brand";
import { goBack } from "~/shared_states/modal";
import { ButtonRegular } from "~/widgets/button";
import { DividerV2 } from "~/widgets/divider";
import { Spacer } from "~/widgets/spacer";
import { SwipeButton } from "~/widgets/swipe_button/swipe_button";

export function getDummyWarningModal(props: { onContinue: () => void }) {
  return (
    <WarningModal
      preReviewPopupDetails={getDummyPreReviewPopupDetails()}
      onContinue={props.onContinue}
    />
  );

  function getDummyPreReviewPopupDetails(): PreReviewPopupDetails {
    let showMultiple = true;
    if (showMultiple) {
      return {
        gradientColor: "#FFFFFF",
        title: "This card has some limitations",
        description: "Multiple redemptions allowed for online mode",
        warnings: [
          {
            icon: "https://gullak-assets.s3.ap-south-1.amazonaws.com/brand-images/block-icon.png",
            message: "This card not valid on Opulent and INOX.",
          },
          {
            icon: "https://gullak-assets.s3.ap-south-1.amazonaws.com/brand-images/block-icon.png",
            message: "This is a single use card for offline.",
          },
        ],
        ctaText: null,
        cancelCtaText: "Cancel",
        headerImageUrl: null,
        optionsTitle: "How are you going to to use this card on {brandName}?",
        options: null,
      };
    }

    return {
      gradientColor: "#FFE4E4",
      title: "Any brand offers / coupons can't be clubbed with this card",
      description:
        "We recommend you reading T&Cs thoroughly before purchasing this card",
      warnings: null,
      ctaText: null,
      cancelCtaText: "Cancel",
      headerImageUrl:
        "https://gullak-assets.s3.ap-south-1.amazonaws.com/ssr/no_offer_club_headsup.png",
      optionsTitle: "How are you going to to use this card on {brandName}?",
      options: null,
    };
  }
}

export function SdkWarningModal(props: {
  preReviewPopupDetails: PreReviewPopupDetails;
  onContinue: () => void;
}) {
  return (
    <div class="w-full rounded-t-3xl bg-white p-2 ">
      <WarningModal {...props} />
    </div>
  );
}

export function WarningModal(props: {
  preReviewPopupDetails: PreReviewPopupDetails;
  onContinue: () => void;
}) {
  return (
    <div
      class="relative flex w-full rounded-2xl border border-baseTertiaryMedium bg-white"
      style={{
        background: "linear-gradient(180deg, #FFE4E4 0%, #FFF 45.94%)",
      }}
    >
      <div class="absolute right-2 top-[8.5px]">
        <CloseModalButton
          onClick={() => {
            goBack();
          }}
        />
      </div>
      <WarningComponent
        preReviewPopupDetails={props.preReviewPopupDetails}
        onContinue={props.onContinue}
      />
    </div>
  );
}

function WarningComponent(props: {
  preReviewPopupDetails: PreReviewPopupDetails;
  onContinue: () => void;
}) {
  const preReviewPopupDetails = props.preReviewPopupDetails;
  return (
    <div class="flex w-full flex-col items-center justify-start">
      <div class="mt-6"></div>
      <div class="flex w-full flex-col items-center justify-start px-4">
        <Show when={preReviewPopupDetails.headerImageUrl}>
          <img
            src={preReviewPopupDetails.headerImageUrl!}
            alt=""
            class=" w-full"
            height={84}
          />
          <Spacer height={24} />
        </Show>
        <span class="text-f12Bold uppercase tracking-[0.24px] text-textNormal">
          Please Note
        </span>
        <Spacer height={4} />
        <Show when={preReviewPopupDetails.title}>
          <span class="text-center text-[19px] text-h4 sm:text-[20px]">
            {preReviewPopupDetails.title}
          </span>
        </Show>
        <Spacer height={8} />
        <Show when={preReviewPopupDetails.description}>
          <span class="text-center text-medium text-textNormal sm:text-normal">
            {preReviewPopupDetails.description}
          </span>
        </Show>
        <Show when={preReviewPopupDetails.warnings?.length ?? 0 > 0}>
          <WarningsList warnings={preReviewPopupDetails.warnings!} />
        </Show>
      </div>
      <Spacer height={16} />
      <DividerV2 class="bg-baseTertiaryMedium" />
      <div class="w-full p-4">
        <TimedButton onContinue={props.onContinue} />
      </div>
    </div>
  );
}

function WarningsList(props: {
  warnings: {
    icon: string;
    message: string;
  }[];
}) {
  return (
    <>
      <Spacer height={20} />
      <div class="flex w-full flex-col gap-3">
        {props.warnings.map((warning) => (
          <div class="flex flex-row items-center gap-3 rounded-2xl border border-baseTertiaryDark bg-white p-4">
            <img class="h-8 w-8" src={warning.icon} alt="" />
            <span class="text-medium text-textDark">{warning.message}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export function TimedButton(props: { onContinue: () => void }) {
  const timeLimitInSec = 5;
  const [timeRemaining, setTimeRemaining] = createSignal(timeLimitInSec);
  const [startTimer, setStartTimer] = createSignal(false);

  // a timer that runs for 5 seconds
  let timer = setInterval(() => {
    setTimeRemaining((prev) => prev - 1);
    if (timeRemaining() === 0) {
      clearInterval(timer);
    }
  }, 1000);

  onMount(async () => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    setStartTimer(true);
  });

  onCleanup(() => {
    clearInterval(timer);
  });

  return (
    <>
      {timeRemaining() > 0 ? (
        <div class="relative h-[52px] w-full overflow-hidden rounded-full">
          <div class="absolute right-0 top-0 block w-full overflow-x-clip  transition-all duration-[5s] ease-linear">
            <TimerComponent class="rounded-r-full" />
          </div>
          <div
            class="dark absolute left-0 top-0 block   overflow-x-clip  transition-all duration-[5s] ease-linear"
            classList={{
              "w-[0px]": !startTimer(),
              "w-full": startTimer(),
            }}
          >
            <TimerComponent class="rounded-l-full" />
          </div>
        </div>
      ) : (
        <>
          <div class="block w-full sm:hidden">
            <SwipeButton
              class="h-[52px]"
              onSwipe={props.onContinue}
              isEnabled={() => true}
              isLoading={() => false}
            >
              Got it · Swipe to proceed
            </SwipeButton>
          </div>
          <div class="hidden sm:block">
            <ButtonRegular
              isEnabled={true}
              height="52px"
              onClick={props.onContinue}
            >
              Get this card
            </ButtonRegular>
          </div>
        </>
      )}
    </>
  );

  function TimerComponent(props: { class?: string }) {
    return (
      <div
        class="line-clamp-1 flex h-[52px] w-full min-w-[360px] flex-row flex-nowrap items-center justify-center  bg-baseTertiaryMedium
     py-[14px] align-middle text-basePrimaryDark dark:bg-basePrimaryDark dark:text-white sm:min-w-[380px]
    "
        classList={{
          [props.class ?? ""]: true,
        }}
      >
        <span class="text-nowrap text-[14px] font-semibold">
          Continue in... {timeRemaining()}
        </span>
      </div>
    );
  }
}
